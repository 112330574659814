import { Center, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";

interface FeaturesCardProps {
  text: string;
  title: string;
  icon: IconType;
  direction?: "left" | "right" | "top" | "bottom"; // New direction prop
}

export const FeaturesCard: React.FC<FeaturesCardProps> = ({ icon, text, title, direction = "bottom" }) => {
  const controls = useAnimation();

  const handleInView = (inView: boolean) => {
    if (inView) {
      controls.start({
        opacity: 1,
        x: 0,
        y: 0,
        transition: { duration: 0.8 },
      });
    } else {
      controls.start(getInitialAnimation(direction));
    }
  };

  // Helper function to return initial animation based on direction prop
  const getInitialAnimation = (direction: "left" | "right" | "top" | "bottom") => {
    switch (direction) {
      case "left":
        return { opacity: 0, x: -50, y: 0 };
      case "right":
        return { opacity: 0, x: 50, y: 0 };
      case "top":
        return { opacity: 0, x: 0, y: -50 };
      case "bottom":
      default:
        return { opacity: 0, x: 0, y: 50 };
    }
  };

  return (
    <InView triggerOnce={false} threshold={0.2} onChange={handleInView}>
      {({ ref }) => (
        <motion.div ref={ref} animate={controls} initial={getInitialAnimation(direction)}>
          <Flex height={{ base: "300px", lg: "400px", xl: "250px" }} bg="primaryAccent" flexDir="column" borderRadius="md">
            <Flex flexDir="row" p={5}>
              <Center mr="5%">
                <Icon as={icon} color="secondary" fontSize={45} />
              </Center>
              <Center>
                <Heading size="md" color="white">
                  {title}
                </Heading>
              </Center>
            </Flex>
            <Center px={5} pb={5}>
              <Text fontSize="md" color="white">
                {text}
              </Text>
            </Center>
          </Flex>
        </motion.div>
      )}
    </InView>
  );
};
