import { Box, Container, Divider, Grid, GridItem, Heading, HStack, Icon, Image, Link, Text, VStack } from "@chakra-ui/react";
import { FiMail, FiPhone, FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import { pages } from "../constants";

const Logo = require("../assets/code-hardy-logo-white.png");

export const Footer = () => {
  const onNavbarItemClick = (href: string) => {
    // scroll to the id contact-form
    const element = document.getElementById(href);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box bg="primary" color="white" py={10}>
      <Container maxW="6xl">
        <Grid templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }} gap={10}>
          {/* Logo Column */}
          <GridItem>
            <Image src={Logo} alt="Brand Logo" width={"75%"} />
          </GridItem>

          {/* Quick Links Column */}
          <GridItem>
            <Heading as="h3" size="md" fontWeight="bold" mb={4}>
              Quick Links
            </Heading>
            <VStack align="start" spacing={2}>
              {pages.map((item) => {
                if (item.hideNavbar) return null;
                return (
                  <Link key={item.id} onClick={() => onNavbarItemClick(item.id)} fontWeight={600} color="white">
                    {item.name}
                  </Link>
                );
              })}
            </VStack>
          </GridItem>

          {/* Contact Info Column */}
          <GridItem>
            <Heading as="h3" size="md" fontWeight="bold" mb={4}>
              Contact Info
            </Heading>
            <VStack align="start" spacing={2}>
              <HStack>
                <Icon as={FiPhone} />
                <Text>+447387197166</Text>
              </HStack>
              <HStack>
                <Icon as={FiMail} />
                <Text>info@code-hardy.com</Text>
              </HStack>
            </VStack>
          </GridItem>

          {/* Follow Us Column */}
          <GridItem>
            <Heading as="h3" size="md" fontWeight="bold" mb={4}>
              Follow Us
            </Heading>
            <HStack spacing={4}>
              <Link href="https://facebook.com" isExternal>
                <Icon as={FiFacebook} boxSize={6} />
              </Link>
              <Link href="https://twitter.com" isExternal>
                <Icon as={FiTwitter} boxSize={6} />
              </Link>
              <Link href="https://instagram.com" isExternal>
                <Icon as={FiInstagram} boxSize={6} />
              </Link>
            </HStack>
          </GridItem>
        </Grid>

        <Divider my={10} borderColor="gray.600" />

        {/* Bottom Section */}
        <Box textAlign="center">
          <Text fontSize="sm">&copy; {new Date().getFullYear()} Code Hardy Ltd. All rights reserved.</Text>
          <HStack justify="center" spacing={4} mt={2}>
            <Link href="/privacy-policy" color="white">
              Privacy Policy
            </Link>
            <Link href="/cookies" color="white">
              Cookies
            </Link>
          </HStack>
        </Box>
      </Container>
    </Box>
  );
};
