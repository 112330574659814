import { Box, Center, Container, Heading, HStack, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";

const OurTeamImg = require("../assets/our-expertise.png");

export const OurExpertise = () => {
  const MotionBox = motion(Box);
  const controlsY = useAnimation();
  const controlsX = useAnimation();

  const handleInViewChange = (inView: boolean) => {
    controlsY.start({ y: inView ? 0 : 200, opacity: inView ? 1 : 0 });
    controlsX.start({ x: inView ? 0 : -200, opacity: inView ? 1 : 0 });
  };

  return (
    <Box id="ourexpertise" bg="primary" w="100vw" py="10%" pos="relative" overflow="hidden" height={{ base: "fit-content", lg: "auto" }}>
      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <InView as="div" triggerOnce={false} threshold={0.4} onChange={handleInViewChange}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: "10%", lg: "15%", xl: "20%" }}>
            <Center pos="relative" w="100%">
              <Image src={OurTeamImg} objectFit="contain" alt="Who Are We Image 1" borderRadius="lg" height="100%" width="100%" />
            </Center>
            <Center h="100%" flexDir="column" alignItems="flex-start">
              <MotionBox animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
                <HStack>
                  <Heading pb={2} as="h1" fontWeight={700} size={{ base: "md", md: "lg" }} color="white">
                    Our
                  </Heading>
                  <Heading pb={2} as="h2" fontWeight={700} size={{ base: "md", md: "lg" }} color="secondary">
                    Expertise
                  </Heading>
                </HStack>
                <Text lineHeight="2" fontSize={{ base: "lg", md: "xl" }} color="whitesmoke" w="100%">
                  At CodeHardy, we believe in transparency, innovation, and reliability. We work closely with our clients every step of the way, ensuring that every project is delivered on time, on budget, and with the highest level of professionalism. Whether you're a startup or an established business, we’re committed to building solutions that are both future-proof and affordable. 
                </Text>
              </MotionBox>
            </Center>
          </SimpleGrid>
        </InView>
      </Container>
    </Box>
  );
};
