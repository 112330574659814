import { Box, Center, Container, Heading, HStack, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";

const HereAtCodeHardyImg = require("../assets/here-at-code-hardy.png");

export const HereAtCodeHardy = () => {
  const MotionBox = motion(Box);
  const controlsY = useAnimation();
  const controlsX = useAnimation();

  const handleInViewChange = (inView: boolean) => {
    controlsY.start({ y: inView ? 0 : 200, opacity: inView ? 1 : 0 });
    controlsX.start({ x: inView ? 0 : -200, opacity: inView ? 1 : 0 });
  };

  return (
    <Box id="hereatcodehardy" bg="primary" w="100vw" py="10%" pos="relative" overflow="hidden" height={{ base: "fit-content", lg: "auto" }}>
      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <InView as="div" triggerOnce={false} threshold={0.4} onChange={handleInViewChange}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: "10%", lg: "15%", xl: "20%" }}>
            <Center pos="relative" w={{ base: "70%", lg: "100%" }}>
              <Image src={HereAtCodeHardyImg} objectFit="contain" alt="Who Are We Image 1" borderRadius="lg" height="100%" width="100%" />
            </Center>
            <Center h="100%" flexDir="column" alignItems="flex-start">
              <MotionBox animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
                <HStack>
                  <Heading pb={2} as="h1" fontWeight={700} size={{ base: "md", md: "lg" }} color="white">
                    Here at
                  </Heading>
                  <Heading pb={2} as="h2" fontWeight={700} size={{ base: "md", md: "lg" }} color="secondary">
                    CodeHardy
                  </Heading>
                </HStack>
                <Text lineHeight="2" fontSize={{ base: "lg", md: "xl" }} color="whitesmoke" w="100%">
                  Founded by seasoned tech professionals, CodeHardy is dedicated to delivering high-quality IT solutions at an affordable price.
                </Text>
                <Text lineHeight="2" fontSize={{ base: "lg", md: "xl" }} color="whitesmoke" w="100%">
                  We specialize in helping startups and small businesses unlock their full potential through technology.
                </Text>
              </MotionBox>
            </Center>
          </SimpleGrid>
        </InView>
      </Container>
    </Box>
  );
};
