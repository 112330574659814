import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerProps, Stack } from "@chakra-ui/react";
import { pages } from "../../constants";

export const MobileDrawer = (props: Omit<DrawerProps, "children">) => {
  const onNavbarItemClick = (href: string) => {
    // scroll to the id contact-form
    const element = document.getElementById(href);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Drawer placement="top" {...props} blockScrollOnMount>
      <DrawerContent bg="primary">
        <DrawerBody mt="16">
          <Stack spacing="6" align="stretch">
            {pages.map((item) => {
              if (item.hideNavbar) return null;
              return (
                <Button
                  key={item.id}
                  size="lg"
                  variant="text"
                  color="white"
                  onClick={() => {
                    props.onClose();
                    onNavbarItemClick(item.id);
                  }}
                >
                  {item.name}
                </Button>
              );
            })}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
