import { Box, Container, Heading, Text, VStack } from "@chakra-ui/react";

export const PrivacyPolicy = () => {
  return (
    <Box bg="primary" color="gray.800" py={10}>
      <Container maxW="4xl">
        <VStack align="start" spacing={6}>
          <Heading color="white" as="h1" size="2xl" mb={4}>
            Privacy Policy
          </Heading>

          <Text color="white" fontSize="lg">
            At Code Hardy Ltd, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website.
          </Text>

          <Heading color="white" as="h2" size="lg" mt={6}>
            Information We Collect
          </Heading>
          <Text color="white">We may collect personal information such as your name, email address, phone number, and any other details you provide voluntarily through our contact forms or newsletter sign-ups.</Text>

          <Heading color="white" as="h2" size="lg" mt={6}>
            How We Use Your Information
          </Heading>
          <Text color="white">
            The information we collect is used to:
            <ul>
              <li>Provide and manage our services</li>
              <li>Respond to your inquiries</li>
              <li>Send updates and promotional materials</li>
              <li>Improve our website and services</li>
            </ul>
          </Text>

          <Heading as="h2" size="lg" mt={6} color="white">
            Sharing Your Information
          </Heading>
          <Text color="white">We do not sell or rent your personal information to third parties. However, we may share your information with trusted partners to help us operate our website and provide services to you.</Text>

          <Heading as="h2" size="lg" mt={6} color="white">
            Your Rights
          </Heading>
          <Text color="white">You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights, please contact us at [contact email].</Text>

          <Heading as="h2" size="lg" mt={6} color="white">
            Changes to This Policy
          </Heading>
          <Text color="white">We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.</Text>

          <Text color="white">If you have any questions about this Privacy Policy, please contact us at [contact email].</Text>
        </VStack>
      </Container>
    </Box>
  );
};
