import { extendTheme } from "@chakra-ui/react";

// Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  fonts: {
    heading: "Rajdhani, sans-serif",
    body: "Rajdhani, sans-serif",
  },
  colors: {
    primary: "#055DCC",
    primaryAccent: "#207DF1",
    secondary: "#F29420",
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 600, // Ensure minimum weight of 600 for headings
      },
    },
    Text: {
      baseStyle: {
        fontWeight: 600, // Ensure minimum weight of 600 for body text
      },
    },
    Button: {
      variants: {
        primary: {
          bg: "primary",
          color: "white",
          _hover: {
            bg: "blue.600",
          },
        },
        secondary: {
          bg: "secondary",
          color: "white",
          _hover: {
            bg: "orange.600",
          },
        },
      },
    },
  },
});
