import { Box, Center, Container, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";
import { PricingCard } from "../components/PricingCard";

export const PricingPlans = () => {
  const MotionBox = motion(Box);
  const controlsY = useAnimation();
  const controlsX = useAnimation();

  const handleInViewChange = (inView: boolean) => {
    controlsY.start({ y: inView ? 0 : 200, opacity: inView ? 1 : 0 });
    controlsX.start({ x: inView ? 0 : -200, opacity: inView ? 1 : 0 });
  };

  return (
    <Box id="pricingplans" bg="primary" w="100vw" py="10%" pos="relative" overflow="hidden" height={{ base: "fit-content", lg: "auto" }}>
      {/* Hero Image in the background */}

      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <InView as="div" triggerOnce={false} threshold={0.4} onChange={handleInViewChange}>
          {/* Left Side: Text and Bullets */}
          <Center h="100%" flexDir="column" alignItems="flex-start">
            <MotionBox animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
              <Text pb={2} fontWeight={700} fontSize={{ base: "lg", md: "xl" }} color="secondary">
                interested in one of our plans?
              </Text>
              <Heading pb={2} as="h1" fontWeight={700} size={{ base: "md", md: "lg" }} color="white">
                Flexible support plans to keep you
              </Heading>
              <Heading pb={4} as="h2" fontWeight={700} size={{ base: "md", md: "lg" }} color="secondary">
                running smoothly
              </Heading>
            </MotionBox>
          </Center>

          <SimpleGrid mt={10} columns={{ base: 1, lg: 3 }} spacing={"5%"}>
            <PricingCard title="Basic" text="Ideal for small businesses that need occasional updates and support. Includes 5 hours of support per month, perfect for minor updates, bug fixes, or small-scale maintenance." stars={1} />
            <PricingCard title="Standard" text="Suited for growing companies with moderate support needs. Includes 10 hours per month plus ongoing system monitoring to ensure your business stays up and running smoothly." stars={2} />
            <PricingCard title="Premium" text="For companies with 24/7 operational requirements. Includes 20+ hours of dedicated support per month, proactive system monitoring, automated backups, and priority response times." stars={3} />
          </SimpleGrid>
        </InView>
      </Container>
    </Box>
  );
};
