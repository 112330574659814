import { Box, Center, Container, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { FiCloud, FiGlobe, FiTool, FiDatabase, FiMonitor } from "react-icons/fi";
import { FeaturesCard } from "../components/FeaturesCard";

export const HowCanWeHelp = () => {
  return (
    <Box id="howcanwehelp" bg="primary" w="100vw" py="10%">
      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <Center h="200px" flexDir="column" alignItems="flex-start">
          <Box>
            <Text pb={2} fontWeight={600} fontSize={{ base: "lg", md: "xl" }} color="secondary">
              how can we help?
            </Text>
            <Heading pb={2} as="h1" fontWeight={500} size={{ base: "md", md: "lg" }} color="white">
              Elevate your brand with
            </Heading>
            <Heading pb={4} as="h2" fontWeight={500} size={{ base: "md", md: "lg" }} color="white">
              Creative Brilliance
            </Heading>
          </Box>
        </Center>
        <SimpleGrid templateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }} templateRows={"1fr 1fr"} columnGap={12} rowGap={12}>
          <FeaturesCard direction="left" title="Cloud Solutions & Hosting" icon={FiCloud} text="We offer secure and scalable cloud environments, leveraging AWS, GCP, and Azure. Whether you need cloud hosting or an entire cloud infrastructure, we ensure your system is safe, fast, and scalable. " />
          <FeaturesCard direction="top" title="Web & App Development" icon={FiGlobe} text="We build custom websites and mobile apps that are optimized for performance, scalability, and designed to engage users. Whether it’s a simple website or a complex mobile app, our development process ensures a seamless experience. " />
          <FeaturesCard direction="right" title="SEO & Analytics" icon={FiTool} text="Our SEO services ensure that your business gets found online. With detailed analytics, we help you understand how to increase traffic and conversions, ensuring that your digital presence drives real-world results. " />
          <FeaturesCard direction="left" title="IT Consultancy" icon={FiTool} text="We provide expert advice on how to align your IT infrastructure with your business goals. From IT strategy to system architecture, our consultants ensure that your technology is working for you, not against you.  " />
          <FeaturesCard direction="bottom" title="Database Management & Setups" icon={FiDatabase} text="Your data is the lifeblood of your business. Our database management and setup services ensure your data is structured, secure, and scalable. From database design to optimization and maintenance, we handle it all, so you can focus on what you do best." />
          <FeaturesCard direction="right" title="Game Development" icon={FiMonitor} text="Engage your audience with interactive entertainment. Whether it's a spin-to-win feature for your marketing campaign or a mini-game for your app, we have the creativity and technical expertise to make it both enjoyable and memorable." />
        </SimpleGrid>
      </Container>
    </Box>
  );
};
