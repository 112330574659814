import { Box, Center, Container, Flex, Heading, Icon, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import { InView } from "react-intersection-observer";
import { MdCircle } from "react-icons/md";
import { BsShieldFillCheck } from "react-icons/bs";

const HeroImg = require("../assets/hero-image.png");
const Image1 = require("../assets/who-are-we-1.png");
const Image2 = require("../assets/who-are-we-2.jpeg");
const Test = require("../assets/test.png");

export const NeedSomethingUnique = () => {
  const MotionBox = motion(Box);
  const controlsY = useAnimation();
  const controlsX = useAnimation();

  const handleInViewChange = (inView: boolean) => {
    controlsY.start({ y: inView ? 0 : 200, opacity: inView ? 1 : 0 });
    controlsX.start({ x: inView ? 0 : -200, opacity: inView ? 1 : 0 });
  };

  return (
    <Box id="needsomethingunique" bg="primary" w="100vw" py="10%" pos="relative" overflow="hidden" height={{ base: "fit-content", lg: "auto" }}>
      {/* Hero Image in the background */}

      <Container p={{ base: 16, lg: 24 }} maxW="full">
        <InView as="div" triggerOnce={false} threshold={0.4} onChange={handleInViewChange}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: "10%", lg: "15%", xl: "10%" }}>
            {/* Left Side: Text and Bullets */}
            <Center h="100%" flexDir="column" alignItems="flex-start">
              <MotionBox animate={controlsY} initial={{ y: 200, opacity: 0 }} transition={{ duration: 1 }}>
                <Text pb={2} fontWeight={700} fontSize={{ base: "lg", md: "xl" }} color="secondary">
                  need something unique?
                </Text>
                <Heading pb={2} as="h1" fontWeight={700} size={{ base: "md", md: "lg" }} color="white">
                  We've got it
                </Heading>
                <Heading pb={4} as="h2" fontWeight={700} size={{ base: "md", md: "lg" }} color="secondary">
                  covered
                </Heading>
                <Text lineHeight="2" fontSize={{ base: "lg", md: "xl" }} color="whitesmoke" w="100%">
                  At CodeHardy, we thrive on designing tailored IT solutions to meet your specific business needs. Whether it’s cutting-edge web or app development, reliable hosting services, game development, or expert IT consultancy, we have the expertise to bring your vision to life.
                </Text>
                <Center flexDir="column" mt={"100px"}>
                  <Flex mb={5} w="100%">
                    <Center flex={0.1}>
                      <Icon as={MdCircle} size="20" color="secondary" />
                    </Center>
                    <Center flex={0.9}>
                      <Text color="white">We specialize in building bespoke websites and applications that are tailored to your specific needs. From custom apps to interactive web platforms, we deliver innovative solutions that elevate user experience and showcase your brand.</Text>
                    </Center>
                  </Flex>
                  <Flex mb={5}>
                    <Center flex={0.1}>
                      <Icon as={MdCircle} size="20" color="secondary" />
                    </Center>
                    <Center flex={0.9}>
                      <Text color="white">We provide reliable and secure hosting services, ensuring your website or application stays online and performs at its best. Our hosting solutions are scalable, flexible, and backed by expert technical support.</Text>
                    </Center>
                  </Flex>
                  <Flex>
                    <Center flex={0.1}>
                      <Icon as={MdCircle} size="20" color="secondary" />
                    </Center>
                    <Center flex={0.9}>
                      <Text color="white">CodeHardy offers expert IT consultancy services to help you stay ahead in the fast-changing tech landscape. We work with you to understand your needs, provide strategic guidance, and implement solutions that drive growth and innovation.</Text>
                    </Center>
                  </Flex>
                </Center>
              </MotionBox>
            </Center>

            {/* Right Side: Overlapping Images */}
            <Center pos="relative" w="100%">
              <Image src={Test} objectFit="contain" alt="Who Are We Image 1" borderRadius="lg" height="100%" width="100%" mr={-48} />
            </Center>
          </SimpleGrid>
        </InView>
      </Container>
    </Box>
  );
};
