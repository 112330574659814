import { Box, Button, ButtonGroup, HStack, Image, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { MobileDrawer } from "./MobileDrawer";
import { ToggleButton } from "./ToggleButton";
import { pages } from "../../constants";

const Logo = require("../../assets/code-hardy-logo-white.png");

export const Navbar = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const mobileNavbar = useDisclosure();

  const onNavbarItemClick = (href: string) => {
    // scroll to the id contact-form
    const element = document.getElementById(href);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box as="nav">
      <Box bg="primary" position="relative">
        <Box py="3" px="7" w="100vw">
          <HStack justify="space-between">
            <Image height="auto" w="120px" src={Logo} onClick={() => onNavbarItemClick("hero")} />
            {isDesktop ? (
              <HStack spacing="8">
                <ButtonGroup size="lg" variant="text" colorScheme="gray" spacing={{ lg: "1", xl: "2" }}>
                  {pages.map((item) => {
                    if (item.hideNavbar) return null;
                    return (
                      <Button
                        key={item.id}
                        size={{ base: "md", xl: "lg" }}
                        color="white"
                        onClick={() => {
                          onNavbarItemClick(item.id);
                        }}
                      >
                        {item.name}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </HStack>
            ) : (
              <>
                <ToggleButton color="white" onClick={mobileNavbar.onToggle} isOpen={mobileNavbar.isOpen} aria-label="Open Menu" zIndex="tooltip" />
                <MobileDrawer isOpen={mobileNavbar.isOpen} onClose={mobileNavbar.onClose} />
              </>
            )}
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};
