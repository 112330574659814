import { About, Contact, Features, Hero } from "../components";
import { PricingCard } from "../components/PricingCard";
import { ContactForm } from "./ContactForm";
import { Footer } from "./Footer";
import { HereAtCodeHardy } from "./HereAtCodeHardy";
import { HowCanWeHelp } from "./HowCanWeHelp";
import { NeedSomethingUnique } from "./NeedSomethingUnique";
import { OurExpertise } from "./OurExpertise";
import { OurTeam } from "./OurTeam";
import { Portfolio } from "./Portfolio";
import { PricingPlans } from "./PricingPlans";
import { WhoAreWe } from "./WhoAreWe";

export const LandingPage = () => (
  <>
    <Hero />
    <WhoAreWe />
    <HowCanWeHelp />
    <NeedSomethingUnique />
    <HereAtCodeHardy />
    <OurTeam />
    <OurExpertise />
    <PricingPlans />
    <Portfolio />
    <ContactForm />
  </>
);
