import { ButtonGroup, Container, IconButton, Image, Stack, Text } from "@chakra-ui/react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
const Logo = require("../assets/codehardy-logo.png");

export const Footer = () => (
  <Container as="footer" role="contentinfo" py={2} minW="100vw" borderTopWidth={0.1} borderTopColor={"whitesmoke"}>
    <Stack spacing={{ base: "4", md: "5" }}>
      <Stack justify="space-between" direction="row" align="center">
        <Image height="auto" w="80px" src={Logo} />

        {/* <ButtonGroup variant="tertiary">
          <IconButton as="a" href="#" aria-label="LinkedIn" icon={<FaLinkedin />} />
          <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub />} />
          <IconButton as="a" href="#" aria-label="Twitter" icon={<FaTwitter />} />
        </ButtonGroup> */}
      </Stack>
      <Text fontSize="md" color="fg.subtle">
        &copy; {new Date().getFullYear()} CODEHARDY LTD. All rights reserved.
      </Text>
    </Stack>
  </Container>
);
